import React from "react"
import styled from "styled-components"
import Section from "../section/section"
import Row from "../row/row"
import { colors } from "../../../utils/styles/styles"
import { ButtonType } from "../button"
import { getSourceLink } from "../../../utils/tracker/links"

import joinus from '../../../images/joinus/joinus.jpg'

const StyledJoinus = styled.div`
  width: 100%;
  padding: 12rem 0px;
  background-image: url(${joinus});
  background-size: cover;
  background-repeat: no-repeat;
  background-color: ${colors.priDark};
  background-blend-mode: multiply;
  background-attachment: fixed;
  text-align: center;
  p {
    color: ${colors.white};
  }
  h4 {
    font-size: 36px;
    text-shadow: 0px 0px px rgba(0, 0, 0, 0.25);
  }
  .container__inner {
    position: relative;
    max-width: 450px;
    width: 100%;
    height: 42px;
    display: flex;
    justify-content: center;
    a {
      display: inline-block;
    }
    input {
      height: 42px;
      border: none;
      border-radius: 30px;
    }
    input {
      width: 100%;
      padding: 0px 168px 0px 10px;
      background: transparent;
      border: 0.5px solid #ccc;
      font-size: 16px;
      &::placeholder {
        color: #ccc;
      }
    }
  }
  div.note {
    margin-top: 10px;
    span {
      font-size: 12px;
    }
  }
  @media (max-width: 768px) {
    padding: 40px 20px;
    flex-direction: column;
    align-items: center;
    h4 {
      font-size: 24px;
    }
    .container__inner {
      max-width: none;
      flex-direction: row;
    }
  }
`

const Joinus = props => {
  return (
    <Section fullwidth={true} padding="0px">
      <StyledJoinus>
        <Row>
          <p>
            Why trying to master digital Marketing, when LeadMirror with A2i can
            help you get there with ease?
          </p>
        </Row>
        <Row>
          <h4>Join Lead Mirror Today</h4>
        </Row>
        <Row isCentered={true} className="container__inner">
          <a
            href={`https://app.leadmirror.com/#/signup/wizard/1?initLandingPage=${getSourceLink()}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <ButtonType.Base variant="secondary" id="footer__joinlm-getstarted">
              Get Started Now!
            </ButtonType.Base>
          </a>
        </Row>
        <Row className="note">
          <span>
            Setting up your account takes less than a minute.
          </span>
        </Row>
      </StyledJoinus>
    </Section>
  )
}

Joinus.defaultProps = {}

export default Joinus
