import React from "react"
import styled from "styled-components"
import Section from "../../../../resuable/section/section"
import { useStaticQuery, graphql } from "gatsby"
import BlogCard from "../../../../resuable/blog/blog-card/blog-card"

const StyledBlogCategoryPosts = styled.div`
  width: 100%;
  div.container__posts {
    width: 100%;
    margin-bottom: 6rem;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    .card {
      margin: 30px;
    }
    .no_results {
      padding: 6rem 0rem;
      text-align: center;
    }
  }
`

const BlogCategoryPosts = ({ posts }) => {
  console.log(posts)
  return (
    <Section padding="0px">
      <StyledBlogCategoryPosts>
        <div className="container__posts">
          {posts.length !== 0 ?
            posts.map((post, index) => {
              const {
                title,
                content,
                slug,
                categories,
                featured_media,
                author,
              } = post.node
              return (
                <BlogCard
                  className="card"
                  key={index}
                  title={title}
                  content={content}
                  slug={slug}
                  categories={categories}
                  featured_media={featured_media}
                  author={author}
                />
              )
            }) : <span className="no_results">No posts found</span>}
        </div>
      </StyledBlogCategoryPosts>
    </Section>
  )
}

BlogCategoryPosts.defaultProps = {}

export default BlogCategoryPosts
