import React from 'react'
import styled from 'styled-components'
import readingTime from 'reading-time'
import { placeholderImage } from '../../../../utils/helpers/generator'
import { Link } from 'gatsby'
import { colors } from '../../../../utils/styles/styles'
import { calculateReadingTime } from '../../../../utils/helpers/blog-helpers'

const StyledBlogCard = styled.div`
  box-sizing: border-box;
  width: 31%;
  max-width: 280px;
  margin: 20px 0px;
  display: flex;
  flex-direction: column;
  a {
    text-decoration: none;
  }
  span.category {
    margin-bottom: 10px;
    padding: 3px 6px;
    display: inline-block;
    background: ${colors.priDark};
    color: #fefefe;
    border-radius: 10px;
    font-size: 10px;
  }
  div.container__image {
    img {
      width: 300px;
      height: 240px;
      margin-bottom: 10px;
      object-fit: cover;
      filter: brightness(60%);
      transition: 0.6s all;
    }
  }
  h4 {
    font-size: 16px;
    margin-bottom: 6px;
  }
  div.author__container {
    display: flex;
    img.author__container-avatar {
      height: 30px;
      width: 30px;
      margin: 0px;
      margin-right: 8px;
      display: inline-block;
      border-radius: 50%;
    }
    div.author__container-meta {
      display: flex;
      flex-direction: column;
      justify-content: center;
      span {
        display: inline-block;
      }
      span.author {
        font-weight: bold;
        font-size: 12px;
        color: #555;
      }
      span.readingtime {
        font-size: 8px;
        color: #666;
      }
    }
  }
  
  &:hover {
    div.container__image {
      img {
        transition: 0.6s all;
        filter: brightness(30%);
      }
    }
  }

  @media (max-width: 768px) {
    width: 100%;
    max-width: 100%;
    margin: 30px 0px;
    div.container__image {
      img {
        width: 100%;
      }
    }
  }
`

const StyledBlogCardFeatured = styled(StyledBlogCard)`
  flex: auto;
  width: 100%;
  max-width: 100%;
  div.container__image {
    img {
      width: 100%;
      height: 300px;
      object-fit: cover;
    }
  }
`

const BlogCardInner = ({
  title,
  content,
  slug,
  featured_media,
  categories,
  author
}) => {
  return (
    <Link to={`/a2i-world/${slug}`}>
      <div className="container__image">
        {
          featured_media !== null ? (
            <img src={featured_media.source_url} alt={title} />
          ) : (
            <img src={placeholderImage(400, 300)} alt="featured image" />
          )
        }
      </div>
      {
        categories !== undefined ? (
          <span className="category">{categories[0].name}</span>
        ) : null
      }
      <h4>{title}</h4>
      {
        author !== undefined ? (
          <div className="author__container">
            {
              author.mpp_avatar !== undefined ? (
                <img className="author__container-avatar" src={author.mpp_avatar.wordpress_96} alt="Author" />
              ) : null
            }
            <div className="author__container-meta">
              <span className="author">{author.name}</span>
              <span className="readingtime">{content !== undefined && readingTime(content).text}</span>
            </div>
          </div>
        ) : null
      }
    </Link>
  )
}

const BlogCard = (props) => {
  if (props.featured) {
    return (
      <StyledBlogCardFeatured>
        <BlogCardInner
          {...props}
        />
      </StyledBlogCardFeatured>
    )
  } else {
    return (
      <StyledBlogCard {...props}>
        <BlogCardInner
          {...props}
        />
      </StyledBlogCard>
    )
  }
}

BlogCard.defaultProps = {
  featured: false,
  title: `Lorem ipsum dolor sit amet.`,
  slug: `/`,
  featured_media: {
    source_url: null,
  },
  author: {
    name: 'Admin',
    avatar_urls: {
      wordpress_96: ''
    }
  }
}

export default BlogCard