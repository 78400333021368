import styled from 'styled-components'
import { dimensions } from '../../../utils/styles/styles'

const Section = styled.section`
  /* Dimensions */
  height: ${props => props.height};
  /* width: ${props => props.fullwidth === true ? '100%' : `${dimensions.siteWidth}` }; */
  width: 100%;
  max-width: ${props => props.fullwidth === true ? '100%' : `${dimensions.siteWidth}` };

  /* Alignment */
  margin: ${props => props.topGap} auto ${props => props.bottomGap};
  padding: ${props => props.padding};
  display: flex;
  justify-content: ${props => {
    if(props.justifyContent === 'left') {
      return 'flex-start'
    }
    if(props.justifyContent === 'center') {
      return 'center'
    }
    if(props.justifyContent === 'right') {
      return 'flex-end'
    }
  }};

  /* Colors, Borders */
  background: ${props => props.background};
  background-size: ${props => props.backgroundSize};
  color: ${props => props.color};
  border: ${props => props.border};

  /* Responsive Setting */
  @media (max-width: 768px) {
    width: 100%;
    height: auto;
    padding: 0px 0px;
    flex-direction: column;
  }
`

Section.defaultProps = {
  height: 'auto',
  fullwidth: false,
  width: '100%',
  topGap: '0px',
  bottomGap: '0px',
  padding: '6rem 2rem',
  fullwidth: false,
  background: 'transparent',
  backgroundSize: 'cover',
  color: '#2b2b2b',
  border: 'none',
  justifyContent: 'center'
}

export default Section
