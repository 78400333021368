import React from 'react'
import styled from 'styled-components'
import Hero from '../../../../resuable/hero/hero'
import SEO from '../../../../resuable/seo/seo'

const StyledBlogCategoryHead = styled.div`
  
`

const BlogCategoryHead = ({
  title
}) => {
  return (
    <React.Fragment>
      <StyledBlogCategoryHead>
        <SEO 
          title={title}
        />
        <Hero
          title={title}
        />
      </StyledBlogCategoryHead>
    </React.Fragment>
  )
}

BlogCategoryHead.defaultProps = {
  title: 'Category'
}

export default BlogCategoryHead