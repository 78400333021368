import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/resuable/layout/layout"
import BlogCategoryPosts from "../../components/page/blog-components/blog-category-page/blog-category-posts/blog-category-posts"
import BlogCategoryHead from "../../components/page/blog-components/blog-category-page/blog-category-head/blog-category-head"
import Joinus from "../../components/resuable/joinus/joinus"

const CategoryTemplate = ({ data, pageContext }) => {
  const { edges: posts } = data.allWordpressPost
  const { name } = data.wordpressCategory
  console.log(data);
  console.log(pageContext)
  console.log(data.allWordpressPost)
  return (
    <Layout isBlogMenuActive={true}>
      <BlogCategoryHead title={name} />
      <BlogCategoryPosts posts={posts} />
      <Joinus />
    </Layout>
  )
}

export default CategoryTemplate

export const query = graphql`
  query categoryQuery($slug: String!, $name: String!) {
    allWordpressPost(
      filter: { categories: { elemMatch: { name: { eq: $name } } } }
    ) {
      edges {
        node {
          title
          slug
          content
          categories {
            name
          }
          featured_media {
            source_url
          }
          author {
            name
            avatar_urls {
              wordpress_96
            }
          }
        }
      }
    }
    wordpressCategory(slug: { eq: $slug }) {
      id
      name
      slug
      count
    }
  }
`
