export const calculateReadingTime = (content) => {
  console.log(content)
  if (content !== undefined) {
    let l = content.length;
    let minutes = 6;
    return minutes;
  }
  return 10;
}

/**
 * @params posts - ARRAY, tag, nOfPostsToReturn - INT 
 */
export const getRecommendedPosts = (posts = [], targetCategoryId, nOfPostsToReturn = 3) => {
  if(posts.length === 0) {
    return posts
  }

  return posts.slice(0, 3)

  // return posts.filter(post => {
  //   post.tags.some((tag) => tag.id === targetTagId)
  // })

}