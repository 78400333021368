import React from 'react'
import styled from 'styled-components'

import Tiles from '../../../images/patterns/papyrus-dark/papyrus-dark.png'
import TilesLight from '../../../images/patterns/papyrus/papyrus.png'

import { dimensions, colors } from '../../../utils/styles/styles'

const HeroContainer = styled.div`
  position: relative;
  width: 100%;
  height: ${props => props.height};
  margin: 0px 0 4rem;
  /* padding: 10rem 1rem; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${props => props.theme.headingColor};
  /* background: ${props => props.background}; */
  background-image: url(${props => props.theme.name === 'dark' ? Tiles : TilesLight });
  /* background-color: ${colors.priDark}; */
  background-repeat: repeat;
  background-attachment: fixed;
  /* background-blend-mode: multiply; */
  clip-path: ellipse(100% 55% at 48% 44%);
  > * {
    color: inherit;
    text-align: center;
    font-size: 18px;
    font-weight: 400;
  }
  h1 {
    position: relative;
    z-index: 2;
    max-width: ${dimensions.siteWidth};
    display: inline-block;
    color: inherit;
    text-align: center;
    text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.35);
    font-size: 48px;
    font-weight: 700;
  }
  h3 {
    font-size: 16px;
  }
  @media (max-width: 768px) {
    height: auto;
    margin: 0 0 2rem;
    padding: 100px 20px 40px;
    clip-path: none;
    h1 {
      font-size: 32px;
    }
    h2 {
      font-size: 18px;
    }
  } 
`

const Hero = ({ children, ...props }) => {
  return (
    <HeroContainer
      {...props}
    >
      {props.title !== undefined ? <h1>{props.title}</h1> : null}
      {children}
    </HeroContainer>
  )
}

Hero.defaultProps = {
  height: '350px',
  title: 'Title',
  background: '#111',
}

export default Hero